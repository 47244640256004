import { Fragment } from "react";
import Nav from "./nav";
import Footer from "./footer";

export function PrivacyPolicy ()
{
	return (
		<Fragment>
			<Nav />
			<div className="text-secondary flex items-center flex-col h-[30vh] pt-[9vw] sm:pt-[10vw] sm:h-[10rem] lm:h-[20rem] tab:h-[18rem] tab:pt-[18vw] sl:h-[14rem]">
				<h1 className="text-4xl font-bold text-secondary text-center mb-2 sm:text-3xl sm:pt-[3rem] lm:text-5xl lm:pt-[3rem]">
					Privacy Policy
				</h1>
				<p class="text-gray-600 mb-6">Effective Date: May 01 2024</p>
			</div>
			<div className="px-[8vw] py-[2vw] text-justify">
				<h2 class="text-3xl font-bold mb-2">Introduction</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					Welcome to Krise Online. Your privacy is of utmost importance to us.
					This Privacy Policy outlines how we collect, use, disclose, and
					protect your personal information when you visit our website,{ " " }
					<a href="https://krise.online" class="text-primary underline">
						krise.online
					</a>
					.
				</p>

				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">1. Information We Collect</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					We collect various types of information to provide and improve our
					services:
				</p>
				<ul class="list-disc list-inside mb-4">
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>Personal Data:</strong> When you register on our site, place
						an order, subscribe to our newsletter, or fill out a form, we
						collect personal information such as your name, email address, phone
						number, and other relevant details.
					</li>
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>Usage Data:</strong> We automatically collect information
						about your interaction with our website. This includes your IP
						address, browser type, operating system, referring URLs, pages
						visited, and the dates/times of your visits.
					</li>
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>Cookies and Tracking Technologies:</strong> We use cookies,
						web beacons, and similar tracking technologies to collect
						information about your activity on our site. Cookies help us enhance
						your experience by remembering your preferences and visits.
					</li>
				</ul>

				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">
					2. How We Use Your Information
				</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					We use the information we collect for the following purposes:
				</p>
				<ul class="list-disc list-inside mb-4">
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>To Provide and Improve Services:</strong> We use your
						personal data to process transactions, provide customer support, and
						improve our website's functionality and content.
					</li>
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>To Personalize User Experience:</strong> Information helps
						us tailor our services to meet your preferences and needs.
					</li>
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>To Communicate:</strong> We use your information to send you
						updates, newsletters, marketing materials, and other communications
						related to our services. You can opt-out of these communications at
						any time.
					</li>
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>To Analyze and Improve:</strong> Usage data helps us analyze
						how our site is used, identify trends, and make improvements to
						enhance user experience.
					</li>
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>To Protect:</strong> We use information to detect, prevent,
						and address technical issues, fraud, and other security concerns.
					</li>
				</ul>

				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">
					3. Information Sharing and Disclosure
				</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					We do not sell, trade, or otherwise transfer your personal data to
					outside parties except as described below:
				</p>
				<ul class="list-disc list-inside mb-4">
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>Service Providers:</strong> We may share your information
						with third-party service providers who assist us in operating our
						website, conducting our business, or servicing you. These providers
						are bound by confidentiality agreements.
					</li>
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>Legal Requirements:</strong> We may disclose your
						information if required by law or in response to valid requests by
						public authorities.
					</li>
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>Business Transfers:</strong> In the event of a merger,
						acquisition, or sale of assets, your personal data may be
						transferred as part of the transaction.
					</li>
				</ul>

				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">4. Data Security</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					We implement appropriate technical and organizational measures to
					protect your personal data against unauthorized access, alteration,
					disclosure, or destruction. However, no method of transmission over
					the internet or electronic storage is completely secure.
				</p>

				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">5. Your Rights</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					You have the right to access, correct, update, or delete your personal
					data. You can also object to the processing of your personal data or
					request restrictions on its use. To exercise these rights, please
					contact us at{ " " }
					<a href="mailto:info@krise.online" class="text-primary underline">
						info@krise.online
					</a>
					.
				</p>

				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">6. Third-Party Links</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					Our website may contain links to third-party sites. We are not
					responsible for the privacy practices or the content of these external
					sites. We encourage you to review the privacy policies of any
					third-party sites you visit.
				</p>

				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">7. Children's Privacy</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					Our services are not intended for individuals under the age of 13. We
					do not knowingly collect personal data from children under 13. If we
					become aware that we have collected such information, we will take
					steps to delete it.
				</p>

				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">
					8. Changes to This Privacy Policy
				</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					We may update this Privacy Policy from time to time. We will notify
					you of any changes by posting the new Privacy Policy on our website.
					You are advised to review this Privacy Policy periodically for any
					changes.
				</p>

				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">9. Contact Us</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					If you have any questions about this Privacy Policy, please contact us
					at:
				</p>
				<ul class="list-disc list-inside mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						Email:{ " " }
						<a href="mailto:info@krise.online" class="text-primary underline">
							info@krise.online
						</a>
					</li>
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						Address: 40 Bennett Road, Englewood, NJ 07631
					</li>
				</ul>

				<p class="mt-6 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					Thank you for trusting Krise Online with your personal information. We
					are committed to protecting your privacy and ensuring a safe online
					experience.
				</p>
			</div><br /><br />
			<Footer />
		</Fragment>
	);
}

export function RefundPolicy ()
{
	return (
		<Fragment>
			<Nav />
			<div className="text-secondary flex items-center flex-col h-[30vh] pt-[9vw] sm:pt-[10vw] sm:h-[10rem] lm:h-[20rem] tab:h-[18rem] tab:pt-[18vw] sl:h-[14rem]">
				<h1 className="text-4xl font-bold text-secondary text-center mb-2 sm:text-3xl sm:pt-[3rem] lm:text-5xl lm:pt-[3rem]">
					Refund Policy
				</h1>
				<p class="text-gray-600 mb-6">Effective Date: 01 May 2024</p>
			</div>
			<div className="px-[8vw] py-[2vw] text-justify">
				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">1. Overview</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					Krise Online is committed to customer satisfaction. As our services
					involve the delivery of digital reports upon purchase, we have
					specific guidelines for refunds.
				</p>
				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">2. Refund Eligibility</h2>
				<ul class="list-disc list-inside mb-4">
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>Digital Nature: </strong> Due to the instant delivery of
						digital reports, refunds are generally not provided once the report
						has been accessed or downloaded.
					</li>
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>Technical Issues: </strong> If you experience technical
						issues preventing access to the report, please contact us within 01
						day of purchase for assistance.
					</li>
				</ul>
				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">3. Refund Requests</h2>
				<ul class="list-disc list-inside mb-4">
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>Process:</strong> To request a refund, contact our customer
						support at info@krise.online. Include your order number and a
						detailed explanation of the issue.
					</li>
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>Review:</strong> Each refund request is reviewed on a
						case-by-case basis. If approved, a refund will be issued to your
						original payment method.
					</li>
				</ul>
				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">4. Exceptions</h2>
				<ul class="list-disc list-inside mb-4">
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>Duplicate Purchases:</strong> Refunds will be granted for
						accidental duplicate purchases.
					</li>
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>Non-Delivery:</strong> If you do not receive the report due
						to a technical error on our part, we will issue a refund.
					</li>
				</ul>
				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">5. Contact Us</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					For any questions or issues regarding our Refund Policy, please
					contact us at:
				</p>
				<p className=" text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					<strong>Email:&nbsp;&nbsp;</strong>
					<a href="mailto:info@krise.online" class="underline">
						info@krise.online
					</a>
					<br />
					<strong>Phone:&nbsp;&nbsp;</strong>
					<a href="tel:8455333324" class="underline">
						8455333324
					</a>
					<br />
					<strong>Address:&nbsp;&nbsp;</strong>
					<a>
						40 Bennett Road Englewood, NJ 07631
					</a>
				</p>
			</div>
			<br />
			<br />
			<Footer />
		</Fragment>
	);
}

export function ShippingPolicy ()
{
	return (
		<Fragment>
			<Nav />
			<div className="text-secondary flex items-center flex-col h-[30vh] pt-[9vw] sm:pt-[10vw] sm:h-[10rem] lm:h-[20rem] tab:h-[18rem] tab:pt-[18vw] sl:h-[14rem]">
				<h1 className="text-4xl font-bold text-secondary text-center mb-2 sm:text-3xl sm:pt-[3rem] lm:text-5xl lm:pt-[3rem]">
					Shipping Policy
				</h1>
				<p class="text-gray-600 mb-6">Effective Date: 01 May 2024</p>
			</div>
			<div className="px-[8vw] py-[2vw] text-justify">
				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">1. Overview</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					Krise Online provides digital services and reports that are delivered
					electronically. As such, there are no physical items to ship, and all
					products are delivered via email or through our online platform.
				</p>
				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">
					2. Delivery of Digital Products
				</h2>
				<ul class="list-disc list-inside mb-4">
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>Instant Access: </strong> Upon successful payment, customers
						will receive instant access to their purchased reports.
					</li>
					<li class="mb-2 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
						<strong>Email Confirmation: </strong> : A confirmation email with a
						link to access the digital report will be sent to the email address
						provided during purchase.
					</li>
				</ul>
				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">3. Technical Support</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					If you encounter any issues with accessing your digital report, please
					contact our customer support for assistance.
				</p>
				<h2 class="text-[1.6vw] sm:text-[1.4rem] lm:text-[1.6rem] sl:text-[1.8rem] font-bold mb-2">4. Contact Us</h2>
				<p class="mb-4 text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					For any questions about our Shipping Policy, please reach out to:
				</p>
				<p className=" text-[1.2vw] sm:text-[1.2rem] lm:text-[1.4rem] sl:text-[1.6rem]">
					<strong>Email:&nbsp;&nbsp;</strong>
					<a href="mailto:info@krise.online" class="underline">
						info@krise.online
					</a>
					<br />
					<strong>Phone:&nbsp;&nbsp;</strong>
					<a href="tel:8455333324" class="underline">
						8455333324
					</a>
					<br />
					<strong>Address:&nbsp;&nbsp;</strong>
					<a>40 Bennett Road Englewood, NJ 07631</a>
				</p>
			</div>
			<br />
			<br />
			<Footer />
		</Fragment>
	);
}
