import { Fragment } from "react";
import Nav from "./nav";
import Footer from "./footer";

export default function Terms() {
	return (
		<Fragment>
			<Nav />
			<div className="text-secondary flex items-center flex-col h-[30vh] pt-[9vw] max-[530px]:pt-[10vw] max-[530px]:h-[10rem] max-[767px]:h-[20rem] max-[992px]:h-[18rem] max-[992px]:pt-[18vw] max-[1199px]:h-[14rem]">
				<h1 className="text-4xl font-bold text-secondary text-center mb-2 max-[530px]:text-3xl max-[530px]:pt-[3rem] max-[767px]:text-5xl max-[767px]:pt-[3rem]">
					Terms and Conditions
				</h1>
			</div>
			<div className="h-[100vh] px-[8vw] py-[4vw] text-justify">
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Non nulla,
				harum molestiae minus quidem amet tempora quibusdam consequatur
				aspernatur alias veritatis neque laborum, aliquid sit molestias cumque
				veniam quas iste!
			</div>
			<Footer />
		</Fragment>
	);
}
