import { Fragment, useState, useEffect } from "react";
import "flowbite";
import "react-phone-number-input/style.css";
import Login from "./login";
import Signup from "./signup";
import logo from "../Assets/logo.png";
import { Dropdown } from "flowbite-react";

function Nav() {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const authenticate = localStorage.getItem("authenticate");
	const token = localStorage.getItem("token");
	const [loginModal, setLoginModal] = useState(false);
	const [signUpModal, setSignUpModal] = useState(false);

	useEffect(() => {
		if (!token && !authenticate) {
			setIsLoggedIn(false);
		} else {
			setIsLoggedIn(true);
		}
	}, [token, authenticate]);

	const Logout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("authenticate");
		window.location.reload();
	};

	const handleShowLoginModal = () => {
		setLoginModal(true);
	};

	const handleShowSignupModal = () => {
		setSignUpModal(true);
	};

	const handleShowLoginFromSignup = () => {
		setLoginModal(true);
		setSignUpModal(false);
	};

	return (
		<div>
			<nav className="flex w-[100%] bg-opacityBg fixed top-0 backdrop-blur-[2px] z-[999]">
				<a className="w-[30%] logo ml-4 sm:w-[18%]" href="#">
					<img src={logo} alt="logo" className="w-[10vw]" />
				</a>
				<div className="w-[70%] flex justify-end items-center sm:w-[82%]">
					<div className="p-[1vw] text-primary text-nav font-fontNav nav-link sm:text-[0.7rem] lm:text-[1rem] tab:text-[1rem]">
						<a href="/#home" className="no-underline">
							Home
						</a>
					</div>
					<div className="p-[1vw] text-primary text-nav font-fontNav nav-link sm:text-[0.7rem] lm:text-[1rem] tab:text-[1rem]">
						<a href="/#challenge" className="no-underline">
							Challenge
						</a>
					</div>
					<div className="p-[1vw] text-primary text-nav font-fontNav nav-link sm:text-[0.7rem] lm:text-[1rem] tab:text-[1rem]">
						<a href="/services" className="no-underline">
							Services
						</a>
					</div>{" "}
					<div className="p-[1vw] text-primary text-nav font-fontNav nav-link sm:text-[0.7rem] lm:text-[1rem] tab:text-[1rem]">
						<a href="/terms" className="no-underline">
							Terms and Conditions
						</a>
					</div>
					<button
						id="dropdownDefaultButton"
						data-dropdown-toggle="dropdown"
						class="text-primary bg-secondary font-fontNav text-nav nav-link inline-flex items-center p-[1vw] sm:text-[0.7rem] lm:text-[1rem] tab:text-[1rem]"
						type="button"
					>
						Policies
						<svg
							class="w-2.5 h-2.5 ms-3"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 10 6"
						>
							<path
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="m1 1 4 4 4-4"
							/>
						</svg>
					</button>
					<div
						id="dropdown"
						class="z-10 hidden bg-secondary divide-y divide-gray-100 rounded-lg shadow w-60 text-primary font-fontNav nav-link"
					>
						<ul
							class="py-2 text-sm text-gray-700 dark:text-gray-200"
							aria-labelledby="dropdownDefaultButton"
						>
							<a
								href="/privacypolicy"
								class="block px-4 py-2 text-tertiary font-fontNav text-nav nav-link hover:text-primary"
							>
								Privacy Policy
							</a>
							<a
								href="/refundpolicy"
								class="block px-4 py-2 text-tertiary font-fontNav text-nav nav-link hover:text-primary"
							>
								Refund Policy
							</a>
							<a
								href="/shippingpolicy"
								class="block px-4 py-2 text-tertiary font-fontNav text-nav nav-link hover:text-primary"
							>
								Shipping Policy
							</a>
						</ul>
					</div>
					{isLoggedIn ? (
						<div className="text-nav font-fontNav sm:text-[0.7rem] lm:text-[1rem] tab:text-[1rem]">
							<button
								type="button"
								className="login bg-secondary border-[0.12vw] border-tertiary text-tertiary mt-[0.2vw] mx-4 p-[0.8vw] w-[8.6vw] rounded-[3vw] sm:w-[4rem] sm:text-[0.8rem] sm:rounded-[2rem] lm:w-[6rem]  tab:w-[6rem]"
								onClick={Logout}
							>
								Logout
							</button>
						</div>
					) : (
						<Fragment>
							<div className="p-[1vw] text-primary text-nav font-fontNav nav-link sm:text-[0.7rem] lm:text-[1rem] tab:text-[1rem]">
								<button type="button" onClick={handleShowSignupModal}>
									Sign Up
								</button>
							</div>
							<div className="text-nav font-fontNav sm:text-[0.8rem] lm:text-[1rem] tab:text-[1rem]">
								<button
									type="button"
									className="login bg-secondary border-[0.12vw] border-tertiary text-tertiary mt-[0.2vw] mx-4 p-[0.8vw] w-[8.6vw] rounded-[3vw] sm:w-[4rem] sm:text-[0.8rem] sm:rounded-[2rem] lm:w-[6rem]  tab:w-[6rem]"
									onClick={handleShowLoginModal}
								>
									Login
								</button>
							</div>
						</Fragment>
					)}
				</div>
			</nav>

			<Login
				show={loginModal}
				onHide={() => {
					setLoginModal(false);
				}}
			/>

			<Signup
				show={signUpModal}
				onHide={() => {
					setSignUpModal(false);
				}}
				showLoginModal={handleShowLoginFromSignup}
			/>
		</div>
	);
}

export default Nav;
