import * as React from "react";
const Location = (props) => (
	<svg
		className="w-[2vw] h-[2vw] max-[530px]:w-[1.6rem] max-[530px]:h-[1.6rem] max-[767px]:w-[2.4rem] max-[767px]:h-[2.4rem] max-[992px]:w-[3rem] max-[992px]:h-[3rem] max-[1200px]:w-[3rem] max-[1200px]:h-[3rem]"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="-4 0 32 32"
		{...props}
	>
		<title>{"location"}</title>
		<path
			fill="#FEC303"
			fillRule="evenodd"
			d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 8a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm0-17C5.373 0 0 5.373 0 12c0 5.018 10.005 20.011 12 20 1.964.011 12-15.05 12-20 0-6.627-5.373-12-12-12Z"
		/>
	</svg>
);
export default Location;
