import { Fragment, useState } from "react";
import Nav from "./nav";
import Footer from "./footer";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

export default function Services() {
	const [selected, setSelected] = useState();
	const [timeDiv, setTimeDiv] = useState(false);
	const [dateDiv, setDateDiv] = useState(true);
	const [timebtn, setTimeBtn] = useState(true);
	const [selectbtn, setSelectBtn] = useState(false);

	return (
		<Fragment>
			<Nav />
			<div className="flex pt-[6.6vw] h-[110vh]">
				<div className="w-[50%] bg-secondary text-primary p-[2vw] flex flex-col justify-items align-items">
					<h1 className="text-center text-[2.6vw] text-primary">
						READY TO GET A RESULT-DRIVEN WEBSITE YOU CAN BE PROUD OF?
					</h1>
					<br />
					<h2 className="text-[1.4vw] text-center text-tertiary">
						JUST FILL OUT THIS FORM AND LET OUR DESIGNERS WORK THEIR MAGIC.
						HERE’S WHAT YOU CAN EXPECT:
					</h2>
					<br />
					<ul class="elementor-icon-list-items text-tertiary text-[1.4vw] ml-[4vw]">
						<li className="my-[1vw]">
							A personalized consultation to understand your goals and vision.
						</li>
						<li className="my-[1vw]">
							A custom website design that reflects your brand and style
						</li>
						<li className="my-[1vw]">
							A custom website design that reflects your brand and style
						</li>
						<li className="my-[1vw]">
							A user-friendly layout that attracts and engages your visitors{" "}
						</li>
						<li className="my-[1vw]">
							A responsive and fast website that works on any device{" "}
						</li>
						<li className="my-[1vw]">
							A hassle-free launch and ongoing support from our team
						</li>
					</ul>
				</div>
				<div
					className={`w-[50%] p-[2vw] flex flex-col ${
						dateDiv ? "visible" : "hidden"
					} justify-center items-center`}
				>
					<h1 className="text-[2.4vw] font-[800] text-center">
						BOOK A CALL AND GET FREE QUOTE
					</h1>
					<DayPicker
						mode="single"
						classNames="bg-[red]"
						selected={selected}
						onSelect={setSelected}
						onDayClick={() => {
							setTimeDiv(true);
							setDateDiv(false);
						}}
					/>
				</div>

				<div
					className={`w-[50%] p-[2vw] flex flex-col ${
						timeDiv ? "visible" : "hidden"
					} justify-start `}
				>
					<button
						onClick={() => {
							setTimeDiv(false);
							setDateDiv(true);
							setTimeBtn(true);
							setSelectBtn(false);
						}}
						className="bg-primary w-[2vw] h-[2vw] rounded-[50%] flex items-center justify-center"
					>
						<svg
							viewBox="0 0 24 24"
							className="w-[1.4vw] h-[1.4vw]"
							fill="none"
						>
							<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
							<g
								id="SVGRepo_tracerCarrier"
								stroke-linecap="round"
								stroke-linejoin="round"
							></g>
							<g id="SVGRepo_iconCarrier">
								{" "}
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M11.7071 4.29289C12.0976 4.68342 12.0976 5.31658 11.7071 5.70711L6.41421 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H6.41421L11.7071 18.2929C12.0976 18.6834 12.0976 19.3166 11.7071 19.7071C11.3166 20.0976 10.6834 20.0976 10.2929 19.7071L3.29289 12.7071C3.10536 12.5196 3 12.2652 3 12C3 11.7348 3.10536 11.4804 3.29289 11.2929L10.2929 4.29289C10.6834 3.90237 11.3166 3.90237 11.7071 4.29289Z"
									fill="#fff"
								></path>{" "}
							</g>
						</svg>
					</button>
					<h1 className="text-[2.4vw] font-[600] text-center">
						Choose Time Slot
					</h1>
					<h1 className="text-[1.4vw] text-center text-primary">
						Duration : 90 Mins
					</h1>
					<br />
					<div
						onClick={() => {
							setTimeBtn(false);
							setSelectBtn(true);
						}}
						className={`${
							timebtn ? "visible" : "hidden"
						} w-[100%] border border-primary h-[4vw] rounded-md font-[600] text-primary flex justify-center items-center text-[1.4vw] cursor-pointer hover:border-[0.2vw]`}
					>
						03: 30AM
					</div>
					<div
						className={`${
							selectbtn ? "visible" : "hidden"
						} flex justify-between mt-[1vw] w-[100%]`}
					>
						<div className="w-[49%] bg-[#6f747e] h-[4vw] rounded-md font-[600] text-white flex justify-center items-center text-[1.4vw] cursor-pointer hover:border-[0.2vw]">
							03: 30AM
						</div>
						<div className="w-[49%] bg-primary h-[4vw] rounded-md font-[600] text-white flex justify-center items-center text-[1.4vw] cursor-pointer hover:border-[0.2vw]">
							Select
						</div>
					</div>
				</div>
			</div>
			<div className="text-secondary flex items-center flex-col h-[30vh] pt-[9vw] max-[530px]:pt-[10vw] max-[530px]:h-[10rem] max-[767px]:h-[20rem] max-[992px]:h-[18rem] max-[992px]:pt-[18vw] max-[1199px]:h-[14rem]">
				<h1 className="text-4xl font-bold text-secondary text-center mb-2 max-[530px]:text-3xl max-[530px]:pt-[3rem] max-[767px]:text-5xl max-[767px]:pt-[3rem]">
					Get Your Website's Premium Audit & Quick Fixes
				</h1>
			</div>
			<div className="px-[6vw] py-[4vw] flex justify-between">
				<div className="w-[24%] border-[0.2vw] border-primary rounded-md shadow-md p-[1vw] flex flex-col justify-start align-center">
					<div className="mx-auto text-[1.4vw] text-[gray] font-[600]">
						Standard
					</div>

					<div className="mx-auto font-[700] text-secondary text-[3vw] mt-[1.8vw] flex flex-col justify-center align-center">
						$50
					</div>
					<div className="text-secondary flex flex-col justify-center align-center mt-[1.8vw] relative bottom-0">
						<a
							href="/checkout/50"
							className="bg-primary text-center cursor-pointer font-[600] p-[0.5vw] text-[1.4vw] hover:bg-[#fae155]"
						>
							Let's go
						</a>
					</div>
					<div className="text-secondary pl-[2vw] mt-[1.8vw] flex flex-col justify-center align-center">
						<ul>
							<li>Comprehensive Analysis</li>
							<li>Usability Assessment</li>
							<li>Performance Evaluation</li>
						</ul>
					</div>
				</div>
				<div className="w-[24%] border-[0.2vw] border-primary rounded-md shadow-md p-[1vw] flex flex-col justify-start align-center">
					<div className="mx-auto text-[1.4vw] text-[gray] font-[600]">Pro</div>
					<div className="mx-auto font-[700] text-secondary text-[3vw] mt-[1.8vw] flex flex-col justify-center align-center">
						$80
					</div>
					<div className="text-secondary flex flex-col justify-center align-center mt-[1.8vw] relative bottom-0">
						<a
							href="/checkout/80"
							className="bg-primary text-center cursor-pointer font-[600] p-[0.5vw] text-[1.4vw] hover:bg-[#fae155]"
						>
							Let's go
						</a>
					</div>
					<div className="text-secondary pl-[2vw] mt-[1.8vw] flex flex-col justify-center align-center">
						<ul>
							<li>Comprehensive Analysis</li>
							<li>Usability Assessment</li>
							<li>Performance Evaluation</li>
							<li>SEO Audit</li>
							<li>Content Quality Check</li>
							<li>Mobile-Friendliness Test</li>
						</ul>
					</div>
				</div>
				<div className="w-[24%] border-[0.2vw] border-primary rounded-md shadow-md p-[1vw] flex flex-col justify-start align-center">
					<div className="mx-auto text-[1.4vw] text-[gray] font-[600]">
						Business
					</div>
					<div className="mx-auto font-[700] text-secondary text-[3vw] mt-[1.8vw] flex flex-col justify-center align-center">
						$120
					</div>
					<div className="text-secondary flex flex-col justify-center align-center mt-[1.8vw] relative bottom-0">
						<a
							href="/checkout/120"
							className="bg-primary text-center cursor-pointer font-[600] p-[0.5vw] text-[1.4vw] hover:bg-[#fae155]"
						>
							Let's go
						</a>
					</div>
					<div className="text-secondary pl-[2vw] mt-[1.8vw] flex flex-col justify-center align-center">
						<ul>
							<li>Comprehensive Analysis</li>
							<li>Usability Assessment</li>
							<li>Performance Evaluation</li>
							<li>SEO Audit</li>
							<li>Content Quality Check</li>
							<li>Mobile-Friendliness Test</li>
							<li>Security Scan</li>
							<li>Actionable Recommendations</li>
							<li>Accessibility Review</li>
							<li>Conversion Optimization</li>
							<li>Analytics Integration</li>
							<li>User Feedback Collection</li>
						</ul>
					</div>
				</div>
				<div className="w-[24%] border-[0.2vw] border-primary rounded-md shadow-md p-[1vw] flex flex-col justify-start align-center">
					<div className="mx-auto text-[1.4vw] text-[gray] font-[600]">
						Custom
					</div>
					<div className="mx-auto font-[700] text-secondary text-[3vw] mt-[1.8vw] flex flex-col justify-center align-center">
						Custom
					</div>

					<div className="text-secondary flex flex-col justify-center align-center mt-[1.8vw] relative bottom-0">
						<a
							href="/checkout/custom"
							className="bg-primary text-center cursor-pointer font-[600] p-[0.5vw] text-[1.4vw] hover:bg-[#fae155]"
						>
							Let's go
						</a>
					</div>
				</div>
			</div>
			<Footer />
		</Fragment>
	);
}
