import logo from "../Assets/logo.png";
import img1 from "../Assets/img1.jpg";
import img2 from "../Assets/img2.jpg";
import img3 from "../Assets/img3.jpg";
import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../api";
import "flowbite";
import Nav from "./nav";
import Login from "./login";
import Location from "../Assets/location";
import Phone from "../Assets/phone";
import Footer from "./footer";

function Index() {
	var primary = "#FEC303";
	const [q1, setq1] = useState(true);
	const [q2, setq2] = useState(true);
	const [q3, setq3] = useState(true);
	const [q4, setq4] = useState(true);
	const [q5, setq5] = useState(true);

	const [div1, setDiv1] = useState(false);
	const [div2, setDiv2] = useState(false);
	const [div3, setDiv3] = useState(false);
	const [div4, setDiv4] = useState(false);
	const authenticate = localStorage.getItem("authenticate");
	const token = localStorage.getItem("token");
	const [clickedValue, setClickedValue] = useState();
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [loginModal, setLoginModal] = useState(false);

	useEffect(() => {
		if (!token && !authenticate) {
			setIsLoggedIn(false);
		} else {
			setIsLoggedIn(true);
		}
	}, [token, authenticate]);

	const Ans1 = () => {
		setq1(!q1);
		setq2(true);
		setq3(true);
		setq4(true);
		setq5(true);
	};
	const Ans2 = () => {
		setq1(true);
		setq2(!q2);
		setq3(true);
		setq4(true);
		setq5(true);
	};
	const Ans3 = () => {
		setq1(true);
		setq2(true);
		setq3(!q3);
		setq4(true);
		setq5(true);
	};
	const Ans4 = () => {
		setq1(true);
		setq2(true);
		setq3(true);
		setq4(!q4);
		setq5(true);
	};
	const Ans5 = () => {
		setq1(true);
		setq2(true);
		setq3(true);
		setq4(true);
		setq5(!q5);
	};

	const Open1 = () => {
		setDiv1(true);
		setDiv2(false);
		setDiv3(false);
		setDiv4(false);
		setClickedValue("50");
	};
	const Open2 = () => {
		setDiv1(false);
		setDiv2(true);
		setDiv3(false);
		setDiv4(false);
		setClickedValue("80");
	};

	const Open3 = () => {
		setDiv1(false);
		setDiv2(false);
		setDiv3(true);
		setDiv4(false);
		setClickedValue("120");
	};

	const Open4 = () => {
		setDiv1(false);
		setDiv2(false);
		setDiv3(false);
		setDiv4(true);
		setClickedValue("custom");
	};

	return (
		<div>
			<Nav />
			<main id="home">
				{/* Home */}
				<div className="text-secondary bg-secondary flex items-center flex-col h-[96vh] pt-[12vw] sm:pt-[22vw] sm:h-[34rem] lm:h-[38rem] tab:h-[38rem] tab:pt-[20vw] sl:h-[38rem]">
					<h1 className="text-6xl font-bold text-primary text-center mb-2 sm:text-4xl sm:pt-[4rem] lm:pt-[6rem]">
						Site Evaluator Tool
					</h1>
					<p className="text-2xl text-tertiary mb-8 font-[700] sm:text-center sm:mx-[0.2rem]">
						Give it a try. You’ve got nothing to lose.
					</p>
					<p className="text-xl text-tertiary mb-8 w-[50%] mx-auto text-center sm:w-[80%] lm:w-[90%] tab:w-[90%]">
						Our Professional Web Site Evaluator Tool is able to evaluate your
						website and identify areas of improvement.
					</p>
				</div>

				{/* Reason */}
				<div className="flex w-full py-[4vw] bg-tertiary flex justify-center items-center sm:flex-col lm:flex-col">
					<div className="w-[50%] text-secondary px-[4vw] py-[2vw] sm:w-[100%] lm:w-[100%]">
						<h1 className="text-5xl font-bold mb-4 tab:text-4xl">
							The What
						</h1>
						<h3 className="text-3xl font-[500] text-primary mb-4 tab:text-2xl">
							What is the Site Evaluator Tool?
						</h3>
						<p className="text-xl">
							A website evaluation helps you understand why you're not selling
							products or acquiring new clients on your website.
						</p>
					</div>
					<div className="w-[50%] flex items-center justify-center sm:w-[100%] sm:mt-[1rem] lm:w-[100%] lm:mt-[1rem]">
						<img
							src={img1}
							alt="Image"
							className="w-[40vw] h-[32vw] rounded-[0.8vw] sm:w-[20rem] sm:h-[14rem] lm:w-[28rem] lm:h-[18rem]"
						/>
					</div>
				</div>

				{/* Advantage */}
				<div className="bg-tertiary flex justify-center items-center py-[4vw] sm:flex-col-reverse lm:flex-col-reverse">
					<div className="w-[50%] flex items-center justify-center sm:w-[100%] sm:mt-[1rem] lm:w-[100%] lm:mt-[1rem]">
						<img
							src={img2}
							alt="Image"
							className="w-[40vw] h-[32vw] rounded-[0.8vw]  sm:w-[20rem] sm:h-[14rem] lm:w-[28rem] lm:h-[18rem]"
						/>
					</div>
					<div className="w-[50%] text-secondary px-[4vw] py-[2vw] sm:w-[100%] lm:w-[100%]">
						<h1 className="text-5xl font-bold mb-4 tab:text-4xl">
							Benefits
						</h1>
						<h3 className="text-3xl font-[500] w-[90%] text-primary mb-4 tab:text-2xl">
							What benefits will you gain from this website evaluation?
						</h3>
						<p className="text-xl">
							<ul>
								<li>A comprehensive usability report</li>
								<li>Detailed heatmap of your pages</li>
								<li>SEO report</li>
								<li>
									Identification of errors such as broken links, design issues,
									and usability problems
								</li>
								<li>
									Simple, layman-friendly explanations of the issues and
									recommended actions
								</li>
							</ul>
						</p>
					</div>
				</div>

				<div className="flex w-full py-[4vw] bg-tertiary flex justify-center items-center  sm:flex-col  lm:flex-col">
					<div className="w-[50%] text-secondary px-[4vw] py-[2vw] sm:w-[100%] lm:w-[100%]">
						<h1 className="text-5xl font-bold mb-4 tab:text-4xl">
							The Why
						</h1>
						<h3 className="text-3xl font-[500] text-primary mb-4 tab:text-2xl">
							Why is having a functional website important for everyone?
						</h3>
						<p className="text-xl">
							<ul>
								<li>
									It serves as your brand's online office, unlike social media.
								</li>
								<li>It enhances your business's credibility.</li>
								<li>
									Research indicates that customers trust businesses with
									websites more than those without - according to surveymonkey.
								</li>
								<li>
									Your competitors likely have websites, giving them an
									advantage.
								</li>
								<li>
									A significant portion of potential customers won't purchase
									your product or service if they don't trust you, with 92%
									cited in studies.
								</li>
							</ul>
						</p>
					</div>
					<div className="w-[50%] flex items-center justify-center sm:w-[100%] sm:mt-[1rem] lm:w-[100%] lm:mt-[1rem]">
						<img
							src={img3}
							alt="Image"
							className="w-[40vw] h-[32vw] rounded-[0.8vw] sm:w-[20rem] sm:h-[14rem] lm:w-[28rem] lm:h-[18rem]"
						/>
					</div>
				</div>

				<div className="bg-tertiary flex justify-center items-center py-[4vw] sm:flex-col-reverse lm:flex-col-reverse">
					<div className="w-[50%] flex items-center justify-center sm:w-[100%] sm:mt-[1rem] lm:w-[100%] lm:mt-[1rem]">
						<img
							src="https://iconicdigitalworld.com/wp-content/uploads/2021/05/Iconic-Project-Timeline-b.svg"
							alt="Image"
							className="bg-[#d1d1d1] w-[40vw] h-[32vw] rounded-[0.8vw]  sm:w-[20rem] sm:h-[14rem] lm:w-[28rem] lm:h-[18rem]"
						/>
					</div>
					<div className="w-[50%] text-secondary px-[4vw] py-[2vw] sm:w-[100%] lm:w-[100%]">
						<h1 className="text-5xl font-bold mb-4 tab:text-4xl">
							Risks
						</h1>
						<h3 className="text-3xl font-[500] w-[90%] text-primary mb-4 tab:text-2xl">
							You might be at risk of missing out!
						</h3>
						<p className="text-xl">
							<ul>
								<li>
									Outdated websites may lead consumers to question the
									authenticity of your product or service.
								</li>
								<li>
									About 80% of website visitors may abandon your site if it
									takes longer than 3 seconds to load.
								</li>
								<li>
									Without search engine optimization, your website may struggle
									to attract attention online.
								</li>
								<li>
									Remember, your competitors likely have websites, potentially
									drawing traffic away from yours.
								</li>
							</ul>
						</p>
					</div>
				</div>

				{/* Work Steps */}
				<div className=" flex flex-wrap items-center justify-center w-[100%] mx-auto h-[80vw] sm:h-auto lm:h-[60rem] tab:h-[60rem]">
					<div className="w-[50%] bg-secondary h-[50%] relative pl-[6vw] p-[2vw] sm:w-[100%] sm:h-[16rem]">
						<img src={logo} alt="logo" className="w-[12vw]" />
						<h1 className="text-8xl text-primary font-bold mb-12 sm:text-5xl lm:text-6xl tab:text-7xl sl:text-7xl">
							How <br /> To <br /> Apply <br />
						</h1>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 47.5 47.5"
							id="Mark"
							className="absolute w-[16vw] top-[18vw] left-[26vw] sm:w-[10rem] sm:h-[10rem] sm:top-[4rem] sm:left-[12rem] lm:w-[12rem] lm:left-[4rem] lm:top-[16rem] tab:w-[12rem] tab:left-[12rem] tab:top-[18rem] sl:left-[22rem] sl:top-[14rem]"
						>
							<defs>
								<clipPath id="a">
									<path
										d="M0 38h38V0H0v38Z"
										fill="#124e66"
										class="color000000 svgShape"
									></path>
								</clipPath>
							</defs>
							<g
								fill="#198fbe"
								clip-path="url(#a)"
								transform="matrix(1.25 0 0 -1.25 0 47.5)"
								class="colorbe1931 svgShape"
							>
								<path
									d="M18 10a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3c.603.006 6 1 6 5 0 2-2 4-5 4-2.441 0-4-2-4-3a3 3 0 1 0-6 0c0 4.878 4.58 9 10 9 8 0 11-5.982 11-11 0-4.145-2.277-7.313-6.413-8.921-.9-.35-1.79-.587-2.587-.747V13a3 3 0 0 0-3-3M21 5a3 3 0 1 0-6 0 3 3 0 0 0 6 0"
									fill={primary}
									class="color000000 svgShape"
								></path>
							</g>
						</svg>
					</div>
					<div className="w-[50%] h-[50%] bg-primary p-[2vw] flex flex-col items-center justify-center sm:w-[100%] sm:h-[22rem]">
						<h2 className="text-5xl font-[600] text-secondary text-center sm:text-[1.8rem] lm:text-3xl tab:text-3xl">
							Fill out our form
						</h2>
						<br />
						<p className="text-secondary text-xl px-[2vw] sm:text-[1.2rem] lm:text-[1.2rem]">
							Just choose the right package and pay to have your site evaluation
							done accordingly. Our team of professionals will get in touch with
							you shortly.
						</p>
					</div>
					<div className="w-[50%] h-[50%] bg-primary p-[2vw] flex flex-col items-center justify-center sm:w-[100%] sm:h-[22rem]">
						<h2 className="text-5xl font-[600] text-secondary text-center sm:text-[1.8rem] lm:text-3xl tab:text-3xl">
							Review the results
						</h2>
						<br />
						<p className="text-secondary text-xl px-[2vw] sm:text-[1.2rem] lm:text-[1.2rem]">
							Take some time to read through the results in the report to
							understand where you are doing a good job and where there is room
							for improvement. We understand that some sections are technical,
							so feel free to reach out to us to better understand the results
							and identify solutions. You can contact Rise by filling out our
							form or calling.
						</p>
					</div>
					<div className="w-[50%] h-[50%] bg-tertiary shadow-md p-[2vw] flex flex-col items-center justify-center sm:w-[100%] sm:h-[22rem]">
						<h2 className="text-5xl font-[600] text-secondary text-center sm:text-[1.8rem] lm:text-3xl tab:text-3xl">
							Schedule a consultation
						</h2>
						<br />
						<p className="text-secondary text-xl px-[2vw] sm:text-[1.2rem] lm:text-[1.2rem]">
							Whether you want us to walk you through your results and answer
							questions, or better understand the types of web development
							services Rise offers, we are here to help. This is a no obligation
							consultation to ensure you understand the areas you could improve
							on your website and how.
						</p>
						<br />
					</div>
				</div>

				{/* Challenge */}
				<div
					className="border-b border-tertiary py-[8vw] flex flex-col items-center justify-center bg-secondary"
					id="challenge"
				>
					<h1 className="text-4xl text-center text-primary font-bold mb-12 sm:text-2xl">
						Get Your Website's Premium Audit & Quick Fixes
					</h1>

					<div className="w-[60%] flex justify-around sm:w-[90%] sm:mx-auto sm:flex-col lm:w-[90%]">
						<div
							onClick={Open1}
							className="w-[20%] font-[600] flex flex-col items-center justify-center text-center bg-tertiary text-xl p-1 rounded-[1vw] cursor-pointer sm:w-full sm:mb-2 sm:h-[4rem] lm:w-[16vw] tab:w-[6rem]"
						>
							Standard <br /> 50 $
						</div>
						<div
							onClick={Open2}
							className="w-[20%] font-[600] flex flex-col items-center justify-center text-center bg-tertiary text-xl p-1 rounded-[1vw] cursor-pointer sm:w-full sm:mb-2 sm:h-[4rem] lm:w-[16vw] tab:w-[6rem]"
						>
							Pro <br /> 80$
						</div>
						<div
							onClick={Open3}
							className="w-[20%] font-[600] flex flex-col items-center justify-center text-center bg-tertiary text-xl p-1 rounded-[1vw] cursor-pointer sm:w-full sm:mb-2 sm:h-[4rem] lm:w-[16vw] tab:w-[6rem]"
						>
							Business <br /> 120$
						</div>
						<div
							onClick={Open4}
							className="w-[20%] font-[600] flex flex-col items-center justify-center text-center bg-tertiary text-xl p-1 rounded-[1vw] cursor-pointer sm:w-full sm:mb-2 sm:h-[4rem] lm:w-[16vw] tab:w-[6rem]"
						>
							Custom
						</div>
					</div>
					<br />

					<div
						className={`p-[2vw] w-[60%] bg-secondary text-white flex justify-around items-start rounded-[1vw] div-shadow ${
							!div1 && "hidden"
						}`}
					>
						{" "}
						<div className="text-center">
							<h2 className="text-2xl mb-2">Standard</h2>
							<h1 className="text-4xl font-bold mb-2 text-primary">$50</h1>
							<br />
							<ul className="text-start">
								<li>Comprehensive Analysis</li>
								<li>Usability Assessment</li>
								<li>Performance Evaluation</li>
							</ul>
							<br />
							<br />
							{isLoggedIn ? (
								<>
									<a
										href={`/checkout/${clickedValue}`}
										className="bg-secondary border-[0.12vw] border-primary py-[0.6vw] px-[2vw] font-[700] text-xl rounded-[2vw] start-btn"
									>
										Let's go
									</a>
								</>
							) : (
								<div>
									<a
										onClick={() => {
											setLoginModal(true);
										}}
										className="cursor-pointer bg-secondary border-[0.12vw] border-primary py-[0.6vw] px-[2vw] font-[700] text-xl rounded-[2vw] start-btn"
									>
										Let's go
									</a>
									<Login
										show={loginModal}
										onHide={() => setLoginModal(false)}
									/>
								</div>
							)}
						</div>
					</div>
					<div
						className={`p-[2vw] w-[60%] bg-secondary text-white flex justify-around items-start rounded-[1vw] div-shadow ${
							!div2 && "hidden"
						}`}
					>
						{" "}
						<div className="text-center">
							<h2 className="text-2xl mb-2">Pro</h2>
							<h1 className="text-4xl font-bold mb-2 text-primary">$80</h1>
							<br />
							<ul className="text-start">
								<li>Comprehensive Analysis</li>
								<li>Usability Assessment</li>
								<li>Performance Evaluation</li>
								<li>SEO Audit</li>
								<li>Content Quality Check</li>
								<li>Mobile-Friendliness Test</li>
							</ul>
							<br />
							<br />
							{isLoggedIn ? (
								<a
									href={`/checkout/${clickedValue}`}
									className="bg-secondary border-[0.12vw] border-primary py-[0.6vw] px-[2vw] font-[700] text-xl rounded-[2vw] start-btn"
								>
									Let's go
								</a>
							) : (
								<div>
									<a
										onClick={() => {
											setLoginModal(true);
										}}
										className="cursor-pointer bg-secondary border-[0.12vw] border-primary py-[0.6vw] px-[2vw] font-[700] text-xl rounded-[2vw] start-btn"
									>
										Let's go
									</a>
									<Login
										show={loginModal}
										onHide={() => setLoginModal(false)}
									/>
								</div>
							)}
						</div>
					</div>
					<div
						className={`p-[2vw] w-[60%] bg-secondary text-white flex justify-around items-start rounded-[1vw] div-shadow ${
							!div3 && "hidden"
						}`}
					>
						{" "}
						<div className="text-center">
							<h2 className="text-2xl mb-2">Business</h2>
							<h1 className="text-4xl font-bold mb-2 text-primary">$120</h1>
							<br />
							<ul className="text-start ml-[4vw]">
								<li>Comprehensive Analysis</li>
								<li>Usability Assessment</li>
								<li>Performance Evaluation</li>
								<li>SEO Audit</li>
								<li>Content Quality Check</li>
								<li>Mobile-Friendliness Test</li>
								<li>Security Scan</li>
								<li>Actionable Recommendations</li>
								<li>Accessibility Review</li>
								<li>Conversion Optimization</li>
								<li>Analytics Integration</li>
								<li>User Feedback Collection</li>
							</ul>
							<br />
							<br />
							{isLoggedIn ? (
								<a
									href={`/checkout/${clickedValue}`}
									className="bg-secondary border-[0.12vw] border-primary py-[0.6vw] px-[2vw] font-[700] text-xl rounded-[2vw] start-btn"
								>
									Let's go
								</a>
							) : (
								<div>
									<a
										onClick={() => {
											setLoginModal(true);
										}}
										className="cursor-pointer bg-secondary border-[0.12vw] border-primary py-[0.6vw] px-[2vw] font-[700] text-xl rounded-[2vw] start-btn"
									>
										Let's go
									</a>
									<Login
										show={loginModal}
										onHide={() => setLoginModal(false)}
									/>
								</div>
							)}
						</div>
					</div>
					<div
						className={`p-[2vw] w-[60%] bg-secondary text-white flex justify-around items-start rounded-[1vw] div-shadow ${
							!div4 && "hidden"
						}`}
					>
						{" "}
						<div className="text-center">
							{isLoggedIn ? (
								<a
									href={`/checkout/${clickedValue}`}
									className="bg-secondary border-[0.12vw] border-primary py-[0.6vw] px-[2vw] font-[700] text-xl rounded-[2vw] start-btn"
								>
									Let's go
								</a>
							) : (
								<div>
									<a
										onClick={() => {
											setLoginModal(true);
										}}
										className="cursor-pointer bg-secondary border-[0.12vw] border-primary py-[0.6vw] px-[2vw] font-[700] text-xl rounded-[2vw] start-btn"
									>
										Let's go
									</a>
									<Login
										show={loginModal}
										onHide={() => setLoginModal(false)}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</main>

			<Footer/>
		</div>
	);
}

export default Index;
