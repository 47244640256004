import * as React from "react";
const Email = (props) => (
	<svg
		className="w-[2.2vw] h-[2.2vw] max-[530px]:w-[1.6rem] max-[530px]:h-[1.6rem] max-[767px]:w-[2.4rem] max-[767px]:h-[2.4rem] max-[992px]:w-[3rem] max-[992px]:h-[3rem] max-[1200px]:w-[3rem] max-[1200px]:h-[3rem]"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			fill="#FEC303"
			fillRule="evenodd"
			d="M3.75 5.25 3 6v12l.75.75h16.5L21 18V6l-.75-.75H3.75Zm.75 2.446v9.554h15V7.695L12 14.514 4.5 7.696Zm13.81-.946H5.69L12 12.486l6.31-5.736Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default Email;
