import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Index from "./Pages/index";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Checkout from "./Pages/checkout";
import Terms from "./Pages/terms";
import {PrivacyPolicy} from "./Pages/policies";
import {RefundPolicy} from "./Pages/policies";
import {ShippingPolicy} from "./Pages/policies";
import Services from "./Pages/services";
import "flowbite";

//Stripe Public Key
const PUBLIC_KEY =
	"pk_test_51MkQliKhLSSCcWANlvI3lQ0UROrQr51KF0YsFb2Pn7v2Sz55vrz7SLb7eOpYlqSymttgjri2EIXUey0vo6W76LEc00QPUY3khc";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

function App() {
	return (
		<BrowserRouter>
			<Elements stripe={stripeTestPromise}>
				<Routes>
					<Route path="/" element={<Index />} />
					<Route path="/checkout/:value" element={<Checkout />} />
					<Route path="/terms" element={<Terms />} />
					<Route path="/privacypolicy" element={<PrivacyPolicy />} />
					<Route path="/refundpolicy" element={<RefundPolicy />} />
					<Route path="/shippingpolicy" element={<ShippingPolicy />} />
					<Route path="/services" element={<Services />} />
				</Routes>
			</Elements>
		</BrowserRouter>
	);
}

export default App;
